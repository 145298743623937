<template>
  <div class="block-webform-block col-md-8 col-md-offset-2 user-edit-form-wrapper">
    <form ref="userEditForm" class="weblab-form" key="form" @submit.prevent>
      <h3 class="title" style="margin-top: 0">Contact Person</h3>
      <!-- FIRST NAME -->
      <div class="form-item required">
        <label>First Name</label>
        <input
          :class="{ error: $v.first_name.$error }"
          placeholder="eg. John"
          autocorrect="none"
          label="First Name"
          name="first_name"
          v-model.trim="$v.first_name.$model"
          type="text"
          size="60"
          maxlength="60"
          class="form-text"
        />
        <div class="error description" v-if="!$v.first_name.required && $v.first_name.$dirty">Field is required</div>
        <!-- <div class="description">Please enter the first name of the main contact person</div> -->
      </div>

      <!-- LAST NAME -->
      <div class="form-item required">
        <label>Last Name</label>
        <input
          :class="{ error: $v.last_name.$error }"
          placeholder="eg. Doe"
          autocorrect="none"
          label="Last Name"
          name="last_name"
          v-model.trim="$v.last_name.$model"
          type="text"
          size="60"
          maxlength="60"
          class="form-text"
        />
        <div class="error description" v-if="!$v.last_name.required && $v.last_name.$dirty">Field is required</div>
        <!-- <div class="description">Please enter the last name of the main contact person</div> -->
      </div>

      <h3 class="title">Company Info</h3>
      <!-- COMPANY NAME -->
      <div class="form-item required">
        <label>Company Name</label>
        <input
          :class="{ error: $v.company_name.$error }"
          placeholder="eg. Acme N.V."
          autocorrect="none"
          label="Company Name"
          name="company_name"
          v-model.trim="$v.company_name.$model"
          type="text"
          size="60"
          maxlength="60"
          class="form-text"
        />
        <div class="error description" v-if="!$v.company_name.required && $v.company_name.$dirty">Field is required</div>
        <!-- <div class="description">Please enter the company name</div> -->
      </div>

      <!-- COMPANY ADDRESS -->
      <div class="form-item required">
        <label>Company Address</label>
        <input
          :class="{ error: $v.company_address.$error }"
          placeholder="eg. Oranjestad 200A"
          autocorrect="none"
          label="Company Address"
          name="company_address"
          v-model.trim="$v.company_address.$model"
          type="text"
          size="60"
          maxlength="60"
          class="form-text"
        />
        <div class="error description" v-if="!$v.company_address.required && $v.company_address.$dirty">Field is required</div>
        <!-- <div class="description">Please enter the company address, must be located on Aruba</div> -->
      </div>
      <div class="address-country-field">Aruba</div>

      <!-- PHONE NUMBER -->
      <div class="form-item required">
        <label>Phone Number</label>
        <div class="phone-input-wrapper">
          <div class="phone-prefix-field">+297</div>
          <input
            :class="{ error: $v.phone_number.$error }"
            placeholder="eg. 5931234"
            autocorrect="none"
            label="Phone Number"
            name="phone_number"
            v-model.trim="$v.phone_number.$model"
            type="tel"
            size="60"
            maxlength="60"
            class="form-text"
          />
        </div>
        <div class="error description" v-if="!$v.phone_number.required && $v.phone_number.$dirty">Field is required</div>
        <div class="error description" v-if="!$v.phone_number.numeric">Field must be numeric with no spaces</div>
        <!-- <div class="description">Please enter the phone number you wished to be contacted at</div> -->
      </div>

      <!-- register btn -->
      <div class="pull-right form-buttons-wrapper">
        <button @click="$parent.editMode = false" class="btn-default btn">
          <i class="glyphicon glyphicon-remove-circle"></i>
          Cancel
        </button>
        <button @click="saveUser()" class="btn-info btn" style="margin-left: 10px">
          <i class="glyphicon glyphicon-floppy-disk"></i>
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, numeric } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],
  data() {
    return {
      first_name: '',
      last_name: '',
      company_name: '',
      company_address: '',
      phone_number: '',
    };
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
    }),
  },
  validations: {
    first_name: {
      required,
    },
    last_name: {
      required,
    },
    company_name: {
      required,
    },
    company_address: {
      required,
    },
    phone_number: {
      required,
      numeric,
    },
  },
  created() {
    this.prefillForm();
  },
  methods: {
    prefillForm() {
      this.first_name = this.userData.first_name;
      this.last_name = this.userData.last_name;
      this.company_name = this.userData.company_name;
      this.company_address = this.userData.company_address;
      this.phone_number = this.userData.phone_number;
    },
    saveUser() {
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('editUser', {
          first_name: this.first_name,
          last_name: this.last_name,
          company_name: this.company_name,
          company_address: this.company_address,
          phone_number: this.phone_number,
        })
        .then((res) => {
          if (Array.isArray(res) && res.length === 0) {
            this.$store.dispatch('getUserData');
            this.$parent.editMode = false;
            this.showSuccess();
          } else {
            this.showError();
          }
        })
        .catch(() => {
          this.showError();
        })
        .finally(() => {
          this.$store.commit('setTransLoader', false);
        });
    },
    showSuccess() {
      this.$snotify.success(`User info successfully edited`, '', {
        timeout: 4000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        position: 'rightTop',
      });
    },
    showError() {
      this.$snotify.error(
        `Error during user edit. Please try again. If problems persists please call ${this.$store.state.config.supportTel} for further assistance.`,
        '',
        {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          position: 'centerTop',
        }
      );
    },
  },
};
</script>

<style lang="scss">
.user-edit-form-wrapper {
  padding-top: 0;
  .weblab-form {
  }
  form {
    .form-item {
      margin: 10px 0;
      label {
        margin-bottom: 5px;
      }
    }
    .form-item.required:before {
      position: absolute;
      top: 32px;
      right: 0;
      content: '';
      vertical-align: super;
      background-image: url(../../../../assets/img/generic/required.svg);
      background-repeat: no-repeat;
      background-size: 6px 6px;
      width: 6px;
      height: 6px;
      margin: 0 0.3em;
    }
  }
  .form-buttons-wrapper {
    margin-top: 30px;
  }
}
</style>