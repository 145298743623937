<template>
  <div class="user-page">
    <navigation></navigation>

    <div class="dashboard-container-right" v-if="created">
      <h2 class="title mt-4">Account</h2>
      <hr />

      <div class="icon-user">
        <svg width="80" height="80" viewBox="0 0 250 250" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_751_100)">
            <path
              d="M125 132.386C155.956 132.386 181.054 103.013 181.054 66.7785C181.054 16.5317 155.957 1.17114 125 1.17114C94.0424 1.17114 68.9474 16.5317 68.9474 66.7785C68.948 103.013 94.043 132.386 125 132.386V132.386Z"
              fill="currentColor"
            />
            <path
              d="M248.777 228.952L220.498 165.248C219.204 162.333 216.932 159.915 214.104 158.442L170.216 135.596C169.248 135.093 168.076 135.191 167.206 135.85C154.793 145.238 140.198 150.201 125 150.201C109.801 150.201 95.2069 145.238 82.7939 135.85C81.9214 135.191 80.7495 135.093 79.7816 135.596L35.8966 158.442C33.067 159.915 30.7972 162.331 29.5025 165.248L1.22297 228.952C-0.726727 233.344 -0.327133 238.369 2.29222 242.397C4.91035 246.425 9.34132 248.829 14.1456 248.829H235.854C240.658 248.829 245.09 246.424 247.708 242.397C250.326 238.369 250.727 233.342 248.777 228.952Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_751_100">
              <rect width="250" height="250" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div style="overflow: hidden; display: flex; justify-content: center" class="mb-3 mr-1 ml-1">
        <span v-if="isDashboardAdmin" class="user-type-indicator mr-1 ml-1">Lab Admin</span>
        <span v-if="isAdmin" class="user-type-indicator admin mr-1 ml-1">Admin</span>
        <span v-if="isAccounting" class="user-type-indicator admin mr-1 ml-1">Accounting</span>
      </div>

      <transition name="scale">
        <div class="user-wrapper" v-if="!editMode" key="user_info">
          <div class="group">
            <label>Username / Email:</label>
            <div class="data-display">{{ userData.email }}</div>
          </div>
          <div class="group">
            <label>Contact Person:</label>
            <div class="data-display">{{ userData.first_name }} {{ userData.last_name }}</div>
          </div>
          <div class="group">
            <label>Company:</label>
            <div class="data-display">{{ userData.company_name }}</div>
          </div>
          <div class="group">
            <label>Address:</label>
            <div class="data-display">{{ userData.company_address }}, Aruba</div>
          </div>
          <div class="group">
            <label>Phone Number:</label>
            <div class="data-display">{{ userData.phone_number }}</div>
          </div>
          <div class="btn-group-custom pull-right">
            <button @click="editMode = true" class="btn-info btn">
              <i class="glyphicon glyphicon-edit"></i>
              Edit
            </button>
            <button @click="logout()" class="btn-default btn" style="margin-left: 10px; background: whitesmoke">
              <i class="glyphicon glyphicon-log-out"></i>
              Logout
            </button>
          </div>
          <div class="clearfix"></div>
          <div class="mt-2 mb-0" style="text-align: right; font-size: 10px; text-transform: uppercase; color: #0084b6">
            {{ $store.state.config.appVersion }}
          </div>
        </div>
        <UserEditForm v-else key="user_form" />
      </transition>
    </div>
  </div>
</template>

<script>
import Navigation from '../../generic/Navigation.vue';
import { mapGetters } from 'vuex';
import UserEditForm from './UserEditForm/UserEditForm.vue';

export default {
  name: 'User',
  components: { Navigation, UserEditForm },
  data() {
    return {
      created: false,
      editMode: false
    };
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
      isDashboardAdmin: 'isDashboardAdmin',
      isAdmin: 'isAdmin',
      isAccounting: 'isAccounting'
    })
  },
  mounted() {
    this.created = true;
  },
  methods: {
    logout() {
      this.$store.dispatch('LOGOUT').then(() => this.$router.push({ name: 'Login' }));
    }
  }
};
</script>

<style lang="scss">
.user-page {
  .fade-enter-active {
    transition: all 0.3s ease;
  }
  .fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-enter, .fade-leave-to
  /* .fade-leave-active for <2.1.8 */ {
    transform: scale(0.01);
    opacity: 0;
  }
}
</style>
